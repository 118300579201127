html, body {
  height: 100%;
}

#root {
  height: 100%
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loop_wrap {
  display: flex;
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.loop_wrap img {
  width: auto;
  height: 100%;
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}

.loop_wrap img:first-child {
  animation: loop 50s -25s linear infinite;
}

.loop_wrap img:last-child {
  animation: loop2 50s linear infinite;
}

.loop_wrap img:first-child {
  animation: loop 50s -25s linear infinite;
}

.loop_wrap img:last-child {
  animation: loop2 50s linear infinite;
}